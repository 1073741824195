import React from 'react';
import Image from "gatsby-image";

class ServiceRow extends React.Component {

    render() {
        let contentRight =
            <div className="row">
                <div className="col-12 d-md-none mb-2 mb-md-0">
                    <h2 className="title">{this.props.title}</h2>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    {(this.props.sizes && (
                        <Image sizes={this.props.sizes} alt={this.props.alt} />
                    )) || (
                            <img
                                src={this.props.src}
                                alt={this.props.alt}
                            />
                        )}
                </div>
                <div className="offset-md-1 col-md-5 pl-3 col-sm-12 mt-3 mt-md-0 pr-0">
                    <h2 className="d-none d-md-block title">{this.props.title}</h2>
                    {this.props.children}
                </div>
            </div>

        let contentLeft =
            <div className="row">
                <div className="col-12 d-md-none mt-2 mt-md-0">
                    <h2 className="title">{this.props.title}</h2>
                </div>
                <div className="col-md-6 col-sm-12 d-block d-md-none mt-3 mt-md-0">
                    {(this.props.sizes && (
                        <Image sizes={this.props.sizes} alt={this.props.alt} />
                    )) || (
                        <img
                            src={this.props.src}
                            alt={this.props.alt}
                        />
                    )}
                </div>
                <div className="col-md-5 col-sm-12 mt-2 mt-md-0">
                    <h2 className="d-none d-md-block title">{this.props.title}</h2>
                    {this.props.children}
                </div>
                <div className="offset-md-1 col-md-6 pl-4  col-sm-12 d-none d-md-block">
                    {(this.props.sizes && (
                        <Image sizes={this.props.sizes} alt={this.props.alt} />
                    )) || (
                        <img
                            src={this.props.src}
                            alt={this.props.alt}
                        />
                    )}
                </div>
            </div>

        return (
            <div className="service-row-component">
                {this.props.flip ? contentLeft : contentRight}
            </div >
        );
    }
}

export default ServiceRow;