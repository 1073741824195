import React, { Component } from 'react';
import { navigate, graphql, StaticQuery } from 'gatsby';
import Image from "gatsby-image";
import { Carousel } from 'react-responsive-carousel';

import Layout from '../components/layout';
import HelmetEx from '../components/helmet-ex';
import ServiceRow from '../components/service-row';
import SlickSlider from '../components/slick-slider';
import WorkSlider from '../components/workslider';

import "react-responsive-carousel/lib/styles/carousel.min.css";

class Home extends Component {

    navigateToContactPage = () => {
        navigate('/contact');
    }

    render() {
        console.log('this.props', this.props);
        return (
            <StaticQuery
                query={graphql`
                    query {
                        firstImageSlider: file(relativePath: { eq: "photos/sliders/home-header-slider-1.png" }) {
                            childImageSharp {
                                sizes(maxWidth: 1200) {
                                    ...GatsbyImageSharpSizes
                                }
                            }
                        }
                        secondImageSlider: file(relativePath: { eq: "photos/sliders/home-header-slider-2.png" }) {
                            childImageSharp {
                                sizes(maxWidth: 1200) {
                                    ...GatsbyImageSharpSizes
                                }
                            }
                        }
                        thirdImageSlider: file(relativePath: { eq: "photos/sliders/home-header-slider-3.png" }) {
                            childImageSharp {
                                sizes(maxWidth: 1200) {
                                    ...GatsbyImageSharpSizes
                                }
                            }
                        }
                        viktoriaInterior: file(relativePath: { eq: "photos/viktoria-interior.png" }) {
                            childImageSharp {
                                sizes(maxWidth: 1200) {
                                    ...GatsbyImageSharpSizes
                                }
                            }
                        }
                        aboutUs: file(relativePath: { eq: "photos/about-us.png" }) {
                            childImageSharp {
                                sizes(maxWidth: 1200) {
                                    ...GatsbyImageSharpSizes
                                }
                            }
                        }
                        signature: file(relativePath: { eq: "photos/signature.png" }) {
                            childImageSharp {
                                sizes(maxWidth: 188) {
                                    ...GatsbyImageSharpSizes
                                }
                            }
                        }
                    }
                    `}
                render={data => <Layout>
                    <div className="home-page">
                        <HelmetEx>
                            <div metadata="title">Viktoria Interior | Custom Window Covering | Elmwood Park, NJ</div>
                            <div metadata="keywords">hunter dougles,alustra,blinds,shading,shades,bergen county,elmwood park,new jersey,NJ,window,Silhouette, Duette,Screen,honycomb</div>
                            <div metadata="description">
                                We're your one-stop source for custom window coverings and more.
                                Come to our showroom and see, feel and operate full size interactive window
                                fashion firsthand.
                            </div>
                        </HelmetEx>
                        <section className="header">
                            <div className="container">
                                <Carousel className="carousel"
                                    autoPlay={true}
                                    showArrows={true}
                                    showStatus={false}
                                    showThumbs={false}
                                    selectedItem={0}
                                    emulateTouch={true}
                                    infiniteLoop={true}
                                    interval={6000}
                                    transitionTime={800}
                                    showIndicators={false}
                                >
                                    <div>
                                        <Image sizes={data.firstImageSlider.childImageSharp.sizes} alt="First slide" />
                                    </div>
                                    <div>
                                        <Image sizes={data.secondImageSlider.childImageSharp.sizes} alt="Second slide" />
                                    </div>
                                    <div>
                                        <Image sizes={data.thirdImageSlider.childImageSharp.sizes} alt="Third slide" />
                                    </div>
                                </Carousel>
                            </div>
                            <div className="content text-center banner">
                                <div className="col-12">
                                    <h1 className="">Surrounded By The Things That You Love Most</h1>
                                </div>
                                <div>
                                    <button type="submit" onClick={this.navigateToContactPage} className="main-button">Contact Us</button>
                                </div>
                            </div>
                        </section>

                        <section className="articles">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <ServiceRow title="WHY VIKTORIA INTERIOR?" sizes={data.viktoriaInterior.childImageSharp.sizes}>
                                            <p>
                                                We're your one-stop source for custom window coverings and more.
                                                Come into our unique showroom to see, feel and operate full size
                                                interactive display window fashion firsthand before you begin to
                                                make your decision. We will then follow up with an in-home visit
                                                to finalize the custom designs.
                                            </p>
                                            <p>
                                                Our distinctive service includes the best
                                                national brands, professional measuring, experienced installation
                                                and stylish design, all provided with a local flair since we're
                                                independently owned and operated. You'll find everything you need
                                                to transform your living space into your dream home
                                        </p>
                                            <p>
                                                Call us today to schedule an appointment for free in-home consultation.
                                                Feel free to visit our shop and get in touch with us.
                                        </p>
                                            <button type="button" onClick={this.navigateToContactPage} className="main-button">GET IN TOUCH</button>
                                        </ServiceRow>
                                    </div>
                                    <div className="row mt-4 pt-4">
                                        <div className="col-12">&nbsp;</div>
                                    </div>
                                    <div className="col-12 mt-4 pt-4">
                                        <ServiceRow title="ABOUT US" flip sizes={data.aboutUs.childImageSharp.sizes}>
                                            <p>
                                                In business since 2003, Viktoria Interior has been serving
                                                customers in the Bergen county area and beyond. Happy customers
                                                include celebrities, restaurants, businesses and homeowners.
                                                To see some of our work in action, visit our Virtual showroom
                                                stop by our Store
                                        </p>
                                            <Image sizes={data.signature.childImageSharp.sizes} style={{ 'max-width': '188px' }} alt="Signature" />
                                        </ServiceRow>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="services">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h2>Services</h2>
                                    </div>
                                    <div className="col-12 text-center">
                                        Count on our professional staff for the finest in interior design service - to suit any decorating
                                        style and any size project
                                </div>
                                </div>
                            </div>
                        </section>
                        <section >
                            <div className="container">
                                <div className="d-none d-md-block">
                                    <WorkSlider />
                                </div>
                                <div className="row">
                                    {/* <div className="col-12 d-none d-md-block">
                                    <SlickSlider slidesToShow={3} />
                                </div> */}
                                    <div className="col-12 d-block d-md-none">
                                        <SlickSlider slidesToShow={1} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Layout >}
            />
        )
    }
}

export default Home;