import React, { Component } from "react";
import Slider from "react-slick";
import FeaturedServicesIcon from '../images/icons/featured-services-01.svg';
import DesignAndDecoratingIcon from '../images/icons/design-and-decorating-01.svg';
import InstallationServices from '../images/icons/installation-services-01.svg'
import ServiceInformation from '../images/icons/service-information-01.svg';
import RepairServices from '../images/icons/repair-services-01.svg';

export default class MobileSlider extends Component {

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.props.slidesToShow,
            slidesToScroll: 1
        };
        return (
            <div className="slider-tow-component">
                <Slider {...settings}>
                    <div className="text-center">
                        <img src={FeaturedServicesIcon} alt="Featured Services" />
                        <h3>FEATURED SERVICES</h3>
                        <p>
                            Count on our professional staff for
                            the finest in interior design service - to suit
                            any decorating style and any size project.
                        </p>
                    </div>
                    <div className="text-center">
                        <img src={DesignAndDecoratingIcon} alt="Design And Decorating" />
                        <h3>DESIGN AND DECORATING</h3>
                        <p>
                            Count on our upholstery department to re-craft
                            your favorite piece of furniture or create a new
                            piece totally from scratch.
                        </p>
                    </div>
                    <div className="text-center">
                        <img src={InstallationServices} alt="Installation Services" />
                        <h3>INSTALLATION SERVICES</h3>
                        <p>
                            Let us do the measuring and installation
                            and make your life easy!
                        </p>
                    </div>
                    <div className="text-center">
                        <img src={ServiceInformation} alt="SERVICE INFORMATION" />
                        <h3>SERVICE INFORMATION</h3>
                        <p>
                            We help you set your decorating budget.
                            Give us a call for a FREE Estimate.
                        </p>
                    </div>
                    <div className="text-center">
                        <img src={RepairServices} alt="REPAIR SERVICES" />
                        <h3>REPAIR SERVICES</h3>
                        <p>
                            Call on our experts to take down or rehang
                            your window treatments professionally and quickly.
                        </p>
                    </div>
                </Slider >
            </div >
        );
    }
}