import React from 'react';
import FeaturedServicesIcon from '../images/icons/featured-services-01.svg';
import DesignAndDecoratingIcon from '../images/icons/design-and-decorating-01.svg';
import InstallationServices from '../images/icons/installation-services-01.svg'
import ServiceInformation from '../images/icons/service-information-01.svg';
import RepairServices from '../images/icons/repair-services-01.svg';
import { navigate } from 'gatsby';

class Slider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slideClassName: "slides slide-position-one",
            slidePhases: []
        }
    }

    onClickRight = () => {
        this.setState({ slideClassName: "slides slide-position-two" });
    }

    onClickLeft = () => {
        this.setState({ slideClassName: "slides slide-position-one" });
    }

    render() {
        return (
            <div className="slider-component">
                <div className="buttons">
                    <button className="left" onClick={this.onClickLeft}></button>
                    <button className="right" onClick={this.onClickRight}></button>
                </div>

                <div ref="slideContainer" className={this.props.className}>
                    <ul className={this.state.slideClassName} >
                        <li className="item" onClick={() => { navigate("/services/featured-services") }}>
                            <img src={FeaturedServicesIcon} alt="Featured Services" />
                            <h3>FEATURED SERVICES</h3>
                            <p>
                                Count on our professional staff for
                                the finest in interior design service - to suit
                                any decorating style and any size project.
                            </p>
                        </li>
                        <li className="item" onClick={() => { navigate("/services/design-and-decorating-services") }}>
                            <img src={DesignAndDecoratingIcon} alt="Design And Decorating" />
                            <h3>DESIGN AND DECORATING</h3>
                            <p>
                                Count on our upholstery department to re-craft
                                your favorite piece of furniture or create a new
                                piece totally from scratch.
                            </p>
                        </li>
                        <li className="item" onClick={() => { navigate("/services/installation-service") }}>
                            <img src={InstallationServices} alt="Installation Services" />
                            <h3>INSTALLATION SERVICES</h3>
                            <p>
                                Let us do the measuring and installation
                                and make your life easy!
                            </p>
                        </li>
                        <li className="item">
                            <img src={ServiceInformation} onClick={() => { navigate("/services/repair-service") }} alt="SERVICE INFORMATION" />
                            <h3>SERVICE INFORMATION</h3>
                            <p>
                                We help you set your decorating budget. 
                                Give us a call for a FREE Estimate.
                            </p>
                        </li>
                        <li className="item">
                            <img src={RepairServices} alt="REPAIR SERVICES" />
                            <h3>REPAIR SERVICES</h3>
                            <p>
                                Call on our experts to take down or rehang
                                your window treatments professionally and quickly.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        clearTimeout(this.slideMoving);
    }
}

export default Slider;