import React from 'react';
import Slider from './slider';

class WorkSlider extends React.Component {

    render() {
        return (
            <div className="work-slider-component">
                <section className="no-padd">
                    <div className="container">
                        <div className="row">
                            <Slider className="logo-carousel" />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default WorkSlider;